<template>
  <v-hover v-slot:default="{ hover }">
    <v-list-item
      active-class=""
      :dark="hover"
      :class="hover ? 'white--text secondary elevation-12' : 'black--text'"
      @click="onClick()"
    >
      <v-list-item-title v-text="title" />
    </v-list-item>
  </v-hover>
</template>

<script>
export default {
  props: {
    title: String,
    click: Function,
  },

  data: () => ({
    hover: false,
  }),

  methods: {
    onClick() {
      if (this.click) {
        this.click(arguments);
      }
    },
  },
};
</script>
