<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="
        $vuetify.breakpoint.smAndDown
          ? setDrawer(!drawer)
          : $emit('input', !value)
      "
    >
      <v-icon v-if="value"> mdi-view-quilt</v-icon>

      <v-icon v-else> mdi-dots-vertical</v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="this.$route.meta.title"
    />

    <v-spacer />

    <v-text-field
      v-if="false"
      :label="$t('search')"
      color="secondary"
      hide-details
      style="max-width: 165px"
    >
      <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
        <v-btn class="mt-n2" elevation="1" fab small>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>

    <div class="mx-3" />

    <v-btn v-if="false" class="ml-2" min-width="0" text>
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          v-if="numberBadge"
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>{{ numberBadge }}</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item
            v-for="noti in notifications"
            :key="noti.key"
            :title="noti.title"
            :click="() => onNotificationClick(noti)"
          >
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-menu
      v-if="false"
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" flat nav>
        <template v-for="(p, i) in profile">
          <v-divider v-if="p.divider" :key="`divider-${i}`" class="mb-2 mt-2" />
          <app-bar-item
            v-else
            :key="`item-${i}`"
            to="/"
            :title="p.title"
            :click="p.click"
          >
          </app-bar-item>
        </template>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
// Components
import AppBarItem from "./AppBarItem";

// Utilities
import { mapState, mapMutations } from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: AppBarItem,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => {
    return {
      notifications: [
        // { key: "1", title: "알림 1" },
        // { key: "2", title: "밀린 일이 잔뜩 쌓여있어요." },
        // { key: "3", title: "어서 일하지 못할까. 찰싹~찰싹~" },
        // { key: "4", title: "어드민 서버가 자꾸 에러가 나고 있어요" },
        // { key: "5", title: "서버야 죽지마~~" },
      ],
      profile: [
        {
          title: "Profile",
        },
        {
          title: "설정",
        },
        {
          divider: true,
        },
        {
          title: "로그아웃",
          click() {
            this.$msal.signOut();
          },
        },
      ],
    };
  },

  computed: {
    ...mapState(["drawer"]),
    numberBadge() {
      // console.log(JSON.stringify({ count: this.notifications.length }));
      return this.notifications.length;
    },
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    onNotificationClick(noti) {
      console.log(`notification clicked: [${noti.key}] ${noti.title}`);
    },
  },
};
</script>
