var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"id":"app-bar","absolute":"","app":"","color":"transparent","flat":"","height":"75"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"elevation":"1","fab":"","small":""},on:{"click":function($event){_vm.$vuetify.breakpoint.smAndDown
        ? _vm.setDrawer(!_vm.drawer)
        : _vm.$emit('input', !_vm.value)}}},[(_vm.value)?_c('v-icon',[_vm._v(" mdi-view-quilt")]):_c('v-icon',[_vm._v(" mdi-dots-vertical")])],1),_c('v-toolbar-title',{staticClass:"hidden-sm-and-down font-weight-light",domProps:{"textContent":_vm._s(this.$route.meta.title)}}),_c('v-spacer'),(false)?_c('v-text-field',{staticStyle:{"max-width":"165px"},attrs:{"label":_vm.$t('search'),"color":"secondary","hide-details":""},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.mdAndUp)?{key:"append-outer",fn:function(){return [_c('v-btn',{staticClass:"mt-n2",attrs:{"elevation":"1","fab":"","small":""}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)]},proxy:true}:null],null,true)}):_vm._e(),_c('div',{staticClass:"mx-3"}),(false)?_c('v-btn',{staticClass:"ml-2",attrs:{"min-width":"0","text":""}},[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1):_vm._e(),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [(_vm.numberBadge)?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":"red","overlap":"","bordered":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.numberBadge))])]},proxy:true}],null,true)},[_c('v-icon',[_vm._v("mdi-bell")])],1)],1):_vm._e()]}}])},[_c('v-list',{attrs:{"tile":false,"nav":""}},[_c('div',_vm._l((_vm.notifications),function(noti){return _c('app-bar-item',{key:noti.key,attrs:{"title":noti.title,"click":function () { return _vm.onNotificationClick(noti); }}})}),1)])],1),(false)?_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,false,3628392491)},[_c('v-list',{attrs:{"tile":false,"flat":"","nav":""}},[_vm._l((_vm.profile),function(p,i){return [(p.divider)?_c('v-divider',{key:("divider-" + i),staticClass:"mb-2 mt-2"}):_c('app-bar-item',{key:("item-" + i),attrs:{"to":"/","title":p.title,"click":p.click}})]})],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }